<template>
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content px-2" style="min-height: 850px; min-width: min-content;">
                <div class="modal-header">
                    <p class="modal-title"
                        style="padding-left: 10px; font-weight: 600; font-size: 20px; color: #636363;">Appointment
                        Doctor</p>
                    <button type="button" aria-label="Close" style="background: transparent; border: none;" @click="closeModal">
                        <CloseIcon />
                    </button>
                </div>
                <div class="content">
                    <Navbar />
                    <div>
                        <div v-if="currentStep ==='STEP_1'">
                            <ContentOne />
                        </div>
                         <div v-else-if="currentStep ==='STEP_2'">
                            <ContentTwo />
                        </div>
                        <div v-else-if="currentStep ==='STEP_3'">
                            <ContentThree />
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>

import CloseIcon from '../../_icons/CloseIcons.vue'
import AddIcon from '../../_icons/AddIcon.vue'
import ContentOne from './_components/ContentOne.vue';
import ContentTwo from './_components/ContentTwo.vue'
import ContentThree from './_components/ContentThree.vue'
import Navbar from './_components/Navbar'
import MapperData from './mapper/mapperModalReservasi'
import { computed } from "vue";
import {machineStep1} from './machine/machineStep1'
import {machineStep2} from './machine/machineStep2'
import {machineStep3} from './machine/machineStep3'

import { useInterpret } from 'xstate-vue2';
import { assign, send } from 'xstate'
import { RepositoryFactory } from '../../../repositories/RepositoryFactory';
import CheckPermission from "@/services/checkPermission.service";
import SweetAlertTemplate from "@/components/_general/SweetAlert";
import BlockUI from "@/components/_js/BlockUI";
const SweetAlert = Vue.extend(SweetAlertTemplate);
const checkPermission = new CheckPermission();
const GenderRepository = RepositoryFactory.get("gender");
const PatientRepository = RepositoryFactory.get('patient')
const DoctorRepository = RepositoryFactory.get("doctor");
const QueueUnitRepository = RepositoryFactory.get("queueUnit");
const JenisPenjaminRepository = RepositoryFactory.get("jenisPenjamin");
const ReservationRepository = RepositoryFactory.get("reservation");
const blockUI = new BlockUI();
export default {
    components: {
        CloseIcon,
        AddIcon,
        ContentOne,
        ContentTwo,
        ContentThree,
        Navbar,
    },
    inject: ['closeModal'],
    props: {
        dataReservasi: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        const step1 = machineStep1.withConfig({
            services:{
                getListPasien: (ctx) => {
                    const params = MapperData.mapperParamSearch(ctx)
                    return PatientRepository.searchPatient(params)
                },
                getDataGenders: ()=> {
                    return GenderRepository.get()
                        .then(_resp => _resp.data)
                }
            },
            actions: {
                setCtxInputDOB: assign({
                    SEARCH_TANGGAL_LAHIR: (ctx, _evt) => _evt.data
                }),
                setCtxSearchPhone: assign({
                    SEARCH_TELP: (ctx, _evt) => _evt.data
                }),
                setCtxSearchNama: assign({
                    SEARCH_NAMA: (ctx, _evt) => _evt.data
                }),
                setCtxPageActive: assign({
                    PAGE_ACTIVE: (ctx, _evt) => _evt.data ?? 1
                }),
                setCtxButtonLoading: assign({
                    BUTTON_SEARCH_STATUS: () => 'LOADING'
                }),
                setCtxModeFormPatient: assign({
                    MODE: () => "FORM_PASIEN"
                }),
                setCtxModeSearchPasien: assign({
                    MODE: () => "SEARCH_PASIEN"
                }),
                setCtxModeDetailPasien: assign({
                    MODE: () => 'DETAIL_PASIEN'
                }),
                setCtxOpenSweetAlert: assign({
                    DIALOG_PHONE: () => true
                }),
                setCtxHideSweetAlert: assign({
                    DIALOG_PHONE: () => false
                }),
                setCtxDefaultFormPatient: assign({
                    FORM: () => {
                        return {
                            NAMA: "",
                            JENIS_KELAMIN: "",
                            TANGGAL_LAHIR: "",
                            EMAIL: "",
                            NO_TELP: "",
                            NOMOR_MRN: "",
                            NAMA_STATUS: "IDLE",
                            JENIS_KELAMIN_STATUS: "IDLE",
                            TANGGAL_LAHIR_STATUS: "IDLE",
                            NO_TELP_STATUS: "IDLE",
                        }
                    },
                    IS_COMPONENT_COUNTRY: () => true,
                    BUTTON_FORM: () => false,
                    DIALOG_PHONE: () => false,
                }),
                setCtxSearchStatusError: assign(() => {
                    return {
                        PAGE_SIZE: 0,
                        TOTAL_LIST: 0,
                        LIST_SEARCH_PASIEN: [],
                        BUTTON_SEARCH_STATUS: 'IDLE'
                    }
                }),
                setCtxListSearchPasien: assign((ctx, _evt) => {
                    const dataResult = _evt.data.data
                    const data = MapperData.mapperListSearch(dataResult.result)
                    const total = dataResult.count
                    return {
                        TOTAL_LIST: total,
                        LIST_SEARCH_PASIEN: data,
                        TOTAL_PAGE: Math.ceil(total / 6),
                        PAGE_SIZE: 6,
                        BUTTON_SEARCH_STATUS: total > 0 ? 'FOUND' : 'IDLE'

                    }
                }),
                setCtxListGender: assign({
                    LIST_JENIS_KELAMIN: (ctx, _evt) => MapperData.mapperListGender(_evt.data)
                }),
                setCtxShowDetailPasien: assign({
                    SELECTED_PASIEN: (context, _evt) => {
                        return {
                            ..._evt.data
                        }
                    }
                }),
                setCtxInputForm: assign({
                    FORM: (ctx, _evt) => {
                        let statusKey = _evt.data.key === 'NO_TELP' ? MapperData.validationPhone(_evt.data.value) : MapperData.validationRequired(_evt.data.value)
                        return {
                            ...ctx.FORM,
                            [`${_evt.data.key}_STATUS`]: statusKey ? 'SUCCESS' : 'ERROR',
                            [_evt.data.key]: _evt.data.value
                        }
                    }
                }),
                setCtxCheckButtonForm: assign({
                    BUTTON_FORM: (ctx) => {
                        const isFormEnabled = ctx.FORM.NAMA_STATUS == 'SUCCESS' && ctx.FORM.JENIS_KELAMIN_STATUS == 'SUCCESS' && ctx.FORM.TANGGAL_LAHIR_STATUS == 'SUCCESS' && ctx.FORM.NO_TELP_STATUS == 'SUCCESS'
                        return isFormEnabled ? 'ENABLE' : 'DISABLE'
                    }
                })
            },
            guards: {
                isHavePatientId: (context) => context.MODE === 'DETAIL_PASIEN',
                isNotHavePatientId: (context) => context.MODE === 'FORM_PASIEN',
                isOtherPageSearch: (context, _evt) => context.PAGE_ACTIVE != _evt.data,
                isNotHaveListGender: (context) => !(context.LIST_JENIS_KELAMIN && context.LIST_JENIS_KELAMIN.length > 0)
            }
        })

        const step2 = machineStep2.withConfig({
            services: {
                getListDokter: (ctx) => {
                    return new Promise((resolve, reject) => {
                        DoctorRepository.getDokterModalReservasi(
                            {
                                "tanggalMulai.equals": ctx.DATE,
                                "tanggalSelesai.equals": ctx.DATE,
                                sort: "asc",
                                page_size: 20
                            },
                            (val) => {
                                const result = MapperData.mapperListAllDokter(val.data)
                                resolve(result)
                            },
                            reject
                        )
                    })
                },
                getSlotReservasi: (ctx) => {
                    const paramQueue = new URLSearchParams();
                    paramQueue.append("tanggal",ctx.DATE);
                    ctx.SELECTED_DOKTER.forEach(_item => {
                        paramQueue.append("dokterId", _item.id);
                    })
                    return new Promise((resolve, reject) => {
                        QueueUnitRepository.getQueueUnitSlotDokter(paramQueue)
                        .then((_resp) => _resp.data)
                        .then((_data) => {
                            const respData = MapperData.setJadwalToDokter(ctx.SLOT_WITH_SELECTED, _data, {date: ctx.DATE,
                                ...(ctx.SELECTED_SLOT_BEFORE ? {slotSelected: ctx.SELECTED_SLOT_BEFORE}: {})
                            })
                            resolve(respData);
                        });
                    })
                }
            },
            actions: {
                setCtxLoadingListDokter: assign({
                    STATUS_LIST_DOKTER: () => 'LOADING'
                }),
                setCtxIdleListDokter: assign({
                    STATUS_LIST_DOKTER: () => 'SUCCESS'
                }),
                setCtxErrorListDokter: assign({
                    STATUS_LIST_DOKTER: () => 'ERROR'
                }),
                setCtxListDokter: assign({
                    LIST_DOKTER:(ctx, _evt) => _evt.data.listDokter,
                    LIST_DOKTER_GROUP: (ctx, _evt) => _evt.data.listWithSpesialis
                }),
                setCtxSelectedDokter: assign((ctx, _evt) => {
                    const selectedSlot = ctx.SELECTED_SLOT
                    const index = _evt.data.findIndex(_item => _item && selectedSlot && _item.id == selectedSlot.id)
                    return {
                        SELECTED_DOKTER: _evt.data,
                        STATUS_SELECTED_DOKTER: _evt.data.length > 0 ? 'SELECTED' : 'EMPTY',
                        SLOT_WITH_SELECTED: MapperData.slotDokterShow(_evt.data, ctx.SLOT_WITH_SELECTED),
                        SELECTED_SLOT: index >= 0 ? selectedSlot : {},
                        STATUS_SELECTED_SLOT: index >= 0  ? true : false
                    }
                }),
                setCtxDefaultSelected: assign({
                    SLOT_WITH_SELECTED: (ctx) => MapperData.slotDokterShow(ctx.SELECTED_DOKTER, [])
                }),
                setCtxSpesialisGroup: assign({
                    IS_SPESIALIS: (ctx, _evt) => _evt.data
                }),
                setCtxModeConsul: assign((ctx, _evt) => {
                    const selectedSlot = ctx.SELECTED_SLOT
                    const listSelectedSlot = ctx.SLOT_WITH_SELECTED
                    const checkSlot = MapperData.checkSlotFromChangeConsult({
                            mode: _evt.data,
                            selectedSlot,
                            list_dokter: listSelectedSlot
                    })
                    return {
                        MODE_KONSULTASI: _evt.data,
                        SELECTED_SLOT: checkSlot,
                        STATUS_SELECTED_SLOT: checkSlot.id  ? true : false
                    }
                }),
                setCtxShowAllSlotReservasi: assign({
                    SLOT_WITH_SELECTED: (ctx, _evt) => _evt.data
                }),
                setCtxErrorShowListSlotDokter: assign({
                    SLOT_WITH_SELECTED: (ctx) => {
                        return ctx.SLOT_WITH_SELECTED.map(_item => {
                            return {
                                ..._item,
                                IS_LOADING_RESERVASI: false
                            }
                        })
                    }
                }),
                setCtxInputSmsEmail: assign((ctx, _evt) => {
                    const key = _evt.data.key
                    const data = _evt.data.value
                    return {
                        DETAIL_PASIEN: {
                            ...ctx.DETAIL_PASIEN,
                            [key]: data,
                            [`${key}_STATUS`]: data.length > 0 ? true: false
                        }
                    }
                }),
                setCtxSelectedRadioPasien: assign((ctx, _evt) => {
                    const key = _evt.data.key
                    const data = _evt.data.value
                    return {
                        [key]: data
                    }
                }),
                setCtxCheckFormPasien: assign({
                    FORM_RADIO_STATUS: (ctx) => {
                        return ctx.SELECTED_MOBILE_APP && ((ctx.SELECTED_SMS && ctx.DETAIL_PASIEN.SMS_STATUS) || (ctx.SELECTED_EMAIL && ctx.DETAIL_PASIEN.EMAIL_STATUS))
                    }
                }),
                setCtxInfoPasienFromContentOne: assign((ctx, _evt) => {
                    return {
                        DETAIL_PASIEN: {
                            SMS: _evt.data.SMS,
                            EMAIL: _evt.data.EMAIL,
                            SMS_STATUS: _evt.data.SMS ? true : false,
                            EMAIL_STATUS: _evt.data.EMAIL ? true: false
                        },
                        SELECTED_SMS: _evt.data.SMS ? true : false,
                        SELECTED_EMAIL: _evt.data.EMAIL ? true : false,
                        SELECTED_MOBILE_APP: true,
                        FORM_RADIO_STATUS: _evt.data.SMS || _evt.data.EMAIL ? true : false
                    }
                }),
                setCtxSelectedSlot: assign({
                    SELECTED_SLOT: (ctx, _evt) => _evt.data,
                    STATUS_SELECTED_SLOT: () => true
                }),
                setCtxSelectedDate: assign({
                    DATE: (ctx, _evt) => _evt.data,
                    SELECTED_DOKTER: () => [],
                    SLOT_WITH_SELECTED: () => [],
                    STATUS_SELECTED_DOKTER: () => 'EMPTY',
                    STATUS_LIST_DOKTER:() => "IDLE",
                    STATUS_SELECTED_SLOT: () => false,
                    SELECTED_SLOT:() => {},
                    STATUS_DATE: (ctx, _evt) => {
                        const isSameOrAfter = MapperData.checkDateSameOrBefore(_evt.data)
                        return isSameOrAfter
                    }
                }),
                setCtxOpenModalBantuan: assign({
                    MODAL_HELP: () => true
                }),
                setCtxHideModalBantuan: assign({
                    MODAL_HELP: () => false
                }),
                setCtxUpdatePrice: assign({
                    UPDATE_PRICE: () => true
                }),
                setCtxSavePrice: assign({
                    UPDATE_PRICE: () => false
                }),
                setCtxPriceTelekonsul: assign({
                    SELECTED_SLOT: (ctx, _evt) => {
                        const selectedSlot = ctx.SELECTED_SLOT
                        return {
                            ...selectedSlot,
                            price: _evt.data
                        }
                    }
                }),
                actionConsult: send({type: 'click:mode:consul'})
            },
            guards: {
                isHaveSelectedSlotTelekonsul: (ctx) => ctx.MODE_KONSULTASI == 'TELEKONSUL' && ctx.SELECTED_SLOT,
                isHaveSelectedSlotConsult: (ctx) => ctx.MODE_KONSULTASI == 'TATAP_MUKA' && ctx.SELECTED_SLOT,
                isSelectedDokter: (ctx) => ctx.STATUS_SELECTED_DOKTER == 'SELECTED',
                isModeTelekonsul: (ctx) => ctx.MODE_KONSULTASI == 'TELEKONSUL'
            }
        })

        const step3 = machineStep3.withConfig({
            services: {
                getDataPenjamin: () => {
                    return new Promise((resolve, reject) => {
                        JenisPenjaminRepository.get({}, (_resp) => {
                            const resp = _resp.data.map(_item => {
                                return {
                                    id: _item.nama,
                                    nama: _item.nama
                                }
                            })
                            resolve(resp)
                        }, reject )
                    })
                }
            },
            actions: {
                setCtxListPenjamin: assign({
                    LIST_PENJAMIN: (ctx, _evt) => _evt.data
                }),
                setCtxSelectPenjamin: assign({
                    SELECTED_PENJAMIN: (ctx, _evt) => _evt.data  ,
                    STATUS_PENJAMIN: (ctx, _evt) => _evt.data ? true : false
                }),
                setCtxInputMessage: assign({
                    MESSAGE: (ctx, _evt) => _evt.data
                })
            },
            guards: {

            }
        })
        return {
            machineStep1: step1,
            machineStep2: step2,
            machineStep3: step3,
            formData: {},
            serviceStep1: null,
            serviceStep2: null,
            serviceStep3: null,
            currentStep: 'STEP_1',
            isHavePermission: false
        }
    },
    provide() {
        return {
            caseData: computed(() => this.formData),
            CURRENT_STEP: computed(() => this.currentStep),
            setCurrentStep: this.selectStepContent,
            inputSearchNama: (val) => {
                this.serviceStep1.send({
                    type: 'input:name',
                    data: val
                })
            },
            inputSearchPhone: (val) => {
                this.serviceStep1.send({
                    type: 'input:phone',
                    data: val
                })
            },
            showOpenSweetAlert: () => {
                this.serviceStep1.send({
                    type: 'open:sweetalert'
                })
            },
            hideSweetAlert: () => {
                this.serviceStep1.send({
                    type: 'close:sweetalert'
                })
            },
            inputSearchDOB: (val) => {
                this.serviceStep1.send({
                    type: 'input:dob',
                    data: val
                })
            },
            buttonSearch: () => {
                this.serviceStep1.send({
                    type: 'button:cari'
                })
            },
            pageActive: (val) => {
                this.serviceStep1.send({
                    type: 'page:list',
                    data: val
                })
            },
            goToFormPatient: () => {
                this.serviceStep1.send({
                    type: 'button:add:pasien'
                })
            },
            goToSearchPatient: () => {
                this.serviceStep1.send({
                    type: 'button:search:pasien'
                })
            },
            inputFormText: (item) => {
                this.serviceStep1.send({
                    type: 'input:form',
                    data: item
                })
            },
            selectedPasien: (item) => {
                this.serviceStep1.send({
                    type: 'button:lanjutkan',
                    data: item
                })
            },
            selectedDateReservasi: (item) => {
                this.serviceStep2.send({
                    type: 'select:date',
                    data: item
                })
            },
            inputSmsPasien: (item)=> {
                this.serviceStep2.send({
                    type: 'input:sms:email',
                    data: item
                })
            },
            selectedCheckBox: (item) => {
                this.serviceStep2.send({
                    type: 'selected:checkbox',
                    data: item
                })
            },
            setDokterSelected: (item) => {
                this.serviceStep2.send({
                    type: 'select:dokter',
                    data: item
                })
            },
            setSpesialis: (val) => {
                this.serviceStep2.send({
                    type: 'checklist:spesialis',
                    data: val
                })
            },
            sendInfoContent: (item) => {
                this.serviceStep2.send({
                    type: 'set:pasien:info',
                    data: item
                })
            },
            setModeConsul: (val) => {
                this.serviceStep2.send({
                    type: 'select:konsultasi',
                    data: val
                })
            },
            selectedSlot: (item) => {
                this.serviceStep2.send({
                    type: 'selected:slot',
                    data: item
                })
            },
            openModalBantuan: () => {
                this.serviceStep2.send({
                    type: 'show:modal:bantuan'
                })
            },
            closeModalBantuan: () => {
                this.serviceStep2.send({
                    type: 'close:modal:bantuan'
                })
            },
            editPrice: () => {
                this.serviceStep2.send({
                    type: 'edit:price'
                })
            },
            savePrice: () => {
                this.serviceStep2.send({
                    type: 'save:price'
                })
            },
            updatedPrice: (val) => {
                this.serviceStep2.send({
                    type: 'update:price',
                    data: val
                })
            },
            selectPenjamin: (item) => {
                this.serviceStep3.send({
                    type: 'select:penjamin',
                    data: item
                })
            },
            inputMessage: (val) => {
                this.serviceStep3.send({
                    type: 'input:message',
                    data: val
                })
            },
            saveReservasi: this.saveReservasi
        }
    },
    mounted () {
        const initState = ''
        this.isHavePermission = this.checkPermission("ReservasiResource.PUT.Reservasi.BackOffice.OverrideTarif") &&
        this.checkPermission("ReservasiResource.POST.Reservasi.BackOffice.OverrideTarif")
        const initContext = MapperData.initContextStep1(this.dataReservasi)
        const initContext2 = MapperData.initContextStep2(this.dataReservasi, this.isHavePermission)
        const initContext3 = MapperData.initContextStep3(this.dataReservasi)
        this.serviceStep1 = useInterpret(
            this.machineStep1,
            {
                devTools: true,
                state: this.machineStep1.transition(
                    initState,
                    { type : ''},
                    initContext
                )
            },
            (state) => {
               const formData = JSON.parse(JSON.stringify(this.formData))
               const context = state.context
                this.formData = {
                    ...formData,
                    ...context
                }
            }
        )
        this.serviceStep2 = useInterpret(
            this.machineStep2,
            {
                devTools: true,
                state: this.machineStep2.transition(
                    initState,
                    { type : ''},
                    initContext2
                )
            },
            (state) => {
               const formData = JSON.parse(JSON.stringify(this.formData))
               const context = state.context
                this.formData = {
                    ...formData,
                    ...context
                }
            }
        )
        this.serviceStep3 = useInterpret(
            this.machineStep3,
            {
                devTools: true,
                state: this.machineStep3.transition(
                    initState,
                    { type : ''},
                    initContext3
                )
            },
            (state) => {
               const formData = JSON.parse(JSON.stringify(this.formData))
               const context = state.context
                this.formData = {
                    ...formData,
                    ...context
                }
            }
        )
    },
    methods: {
        selectStepContent (val) {
            this.currentStep = val
        },
        checkPermission: function (method) {
            return checkPermission.isCanAccess(method);
        },
        saveReservasi() {
            const isUpdated = this.dataReservasi.generatedId ? true : false
            // const dataReservasi = MapperData.
            const that = this
            new SweetAlert().showConfirm({
                swalTitle: "Apakah Anda yakin?",
                swalText: isUpdated ? "Data Reservasi akan di updated" : "Data Reservasi Baru akan ditambahkan.",
                swalType: "info",
                onConfirmButton: function () {
                    blockUI.blockModal();
                    if(!isUpdated) {
                        that.createReservasi(that.isHavePermission, that.formData)
                    } else {
                        that.updateReservasi(that.isHavePermission, that.formData, that.dataReservasi.generatedId)
                    }
                },
                onCancelButton: function () {
                    blockUI.unblockModal();
                }
            })
        },
        checkCC() {
            return this.checkPermission("ReservasiResource.PUT.Reservasi.BackOffice.RestrictedSlot") &&
            this.checkPermission("ReservasiResource.POST.Reservasi.BackOffice.RestrictedSlot")
        },
        async createReservasi(isHavePermissionSpv, payload) {
            const payloadData = MapperData.payloadCreateReservasi(payload, isHavePermissionSpv)
            const isCC = this.checkCC()
            let reservation;
            if (isHavePermissionSpv) {
                reservation = ReservationRepository.createOverride(payloadData);
            } else if (isCC) {
                reservation = ReservationRepository.create(payloadData);
            } else {
                reservation = ReservationRepository.createReservationNurse(payloadData);
            }

            const that = this
            await reservation.then(() => {
                    new SweetAlert().showInfo({
                        swalTitle: "Berhasil!",
                        swalText: payloadData.teleconsult ? "Reservasi Telekonsul sedang di proses." : "Reservasi baru berhasil ditambahkan.",
                        swalType: payloadData.teleconsult  ? "info" : "success",
                        onConfirmButton: function () {
                            blockUI.unblockModal();
                            that.closeModal()
                        },
                    });
            }).catch((err) => {
                const errorMessage = err.response?.data?.message
                const errorModalText =
                    errorMessage === "ERR_UNSUCCESSFUL" ?
                    "Reservasi baru tidak dapat dibuat." : "Silakan pilih slot waktu atau jadwal lainnya."

                new SweetAlert().showInfo({
                    swalTitle: "Gagal!",
                    swalText: errorModalText,
                    swalType: "error",
                    onConfirmButton: function () {
                        blockUI.unblockModal();
                    },
                })
            })
        },
        async updateReservasi(isHavePermissionSpv, payload, generatedId) {
            const payloadData = MapperData.payloadUpdateReservasi(payload, generatedId, isHavePermissionSpv)
            const isCC = this.checkCC()
            let reservation;
            if (isHavePermissionSpv) {
                reservation = ReservationRepository.postRescheduleOverride(payloadData)
            } else if (isCC) {
                reservation = ReservationRepository.postRescheduleAppointment(payloadData)
            } else {
                reservation = ReservationRepository.postRescheduleNurse(payloadData)
            }
            const that = this
            await reservation.then(() => {
                new SweetAlert().showInfo({
                        swalTitle: "Berhasil!",
                        swalText: "Reservasi berhasil diubah.",
                        swalType: "success",
                        onConfirmButton: function () {
                            blockUI.unblockModal();
                            that.closeModal()
                        },
                    });
            }).catch(() => {
                new SweetAlert().showInfo({
                    swalTitle: "Gagal!",
                    swalText: "Reservasi tidak dapat diubah",
                    swalType: "error",
                    onConfirmButton: function () {
                    blockUI.unblockModal();
                    },
                });
            });
        }
    }
}
</script>

<style scoped>
.form-control::placeholder {
    font-size: 14px;
    font-weight: 600;
    color: #E4E4E4;
}

.modal-xl {
    min-height: 923px;
}

.modal-header {
    border-bottom: none;
    padding-bottom: 0;
}

/* .modal-body {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
    padding-bottom: 0;
} */

.navbar {
    border-bottom: 1px solid #eeeef4;
    display: flex;
    flex-direction: column;
    padding: 0;
    padding-bottom: 1rem;
}

.navbar-inner {
    padding: 2rem 1rem 0.5rem;
}

.navbar-inner-items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
}

.navbar-inner-item{
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.25rem;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 1rem 1rem;
    font-weight: 500;
}

.form-control {
    height: 40px;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 6px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-primary {
    color: #fff;
    background-color: #5578EB;
    border-color: #5578EB;
    height: 40px;
    border-radius: 12px;
}

.btn-outline-primary {
    color: #5578EB;
    border-color: #5578EB;
}

.table-responsive .table {
    width: 100%;
}

.table-style {
    background-color: #F3F8FF;
    border-right: 1px solid #E4E4E4;
    color: #23415B;
    font-weight: 700;
    font-size: 16px;
    line-height: 19.5px;
    height: 50px;
}

.table-responsive .table thead th.table-style {
    border-top: none;
    border-bottom: none !important;
}

.table-responsive .table thead th.table-style:last-child {
    border-right: none;
}

.table td, .table th {
    border-top: none !important;
}

.icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 1.2rem;
    color: #999;
    cursor: pointer;
    padding: 0;
    line-height: 1;
}

.input-wrapper {
    position: relative;
}

.content {
    padding: 0rem 2rem 0rem;
}
</style>
